import React, { useState, useContext, useEffect } from "react";
import ContactForm from "./form/ContactForm";
import ContactFormSuccess from "./ContactFormSuccess";
import ContactFormFailure from "./ContactFormFailure";
import { LoadingContext } from "../../components/loading/Loading";
import ContactImageComponent from "./ContactImageComponent";

const Contact = () => {
  const setLoading = useContext(LoadingContext);
  const [formStatus, setFormStatus] = useState(null);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [setLoading]);

  return (
    <div className="row min-vh-100">
      <ContactImageComponent />
      {!formStatus ? (
        <ContactForm setFormStatus={setFormStatus} />
      ) : formStatus === "success" ? (
        <ContactFormSuccess />
      ) : (
        <ContactFormFailure />
      )}
    </div>
  );
};

export default Contact;
