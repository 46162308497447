import React, { useContext } from "react";
import { UserContext } from "./UserEditor";
const EmailComponent = () => {
  const { form, setForm } = useContext(UserContext);

  return (
    <div className="form-group">
      <label className="h3" htmlFor="email">
        Email Address
      </label>
      <input
        className="form-control"
        name="email"
        value={form.email}
        onChange={(event) =>
          setForm({
            ...form,
            [event.target.name]: event.target.value,
          })
        }
      />
    </div>
  );
};

export default EmailComponent;
