import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { instance } from "../../stores/AuthStore";

const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [nextLink, setNextLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const handleScroll = debounce((event) => {
    if (!isLoading && hasMore) {
      const bottom =
        event.target.scrollHeight - event.target.scrollTop ===
        event.target.clientHeight;
      if (bottom) {
        setIsLoading(true);
        instance.get(nextLink).then((res) => {
          console.log(posts.length < res.data.count ? true : false);
          setPosts((result) => [...result, ...res.data.results]);
          setNextLink(res.data.next);
          setIsLoading(false);
          setHasMore(posts.length < res.data.count ? true : false);
        });
      }
    }
  }, 1000);

  useEffect(() => {
    setIsLoading(true);
    instance.get("/blog/feed/?page_size=5").then((res) => {
      setPosts(res.data.results);
      setNextLink(res.data.next);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchmove", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.addEventListener("touchmove", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="container">
      {posts.map((post) => (
        <div className="row my-5">
          <div className="col-12 ">
            <h3>{post.title}</h3>
            <p className="d-inline-block">{post.excerpt}</p>
            <p className="small">{post.publish_date}</p>
            <div className="mt-2">
              {post.tags.map((tag) => (
                <span className="p-2 mr-1 mb-1 font-weight-normal badge badge-pill badge-primary">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Feed;
