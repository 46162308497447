import React from "react";
import { useWindowSize } from "../../utils/customHooks";
import NavigationBar from "./NavgationBar";
import NavigationDrawer from "./NavigationDrawer";
import { routes } from "../../RouteConfig";
import authStore from "../../stores/AuthStore";
import { observer } from "mobx-react";
import Login from "../../admin/users/login/Login";
import Logout from "../../admin/users/logout/Logout";
import Admin from "../../admin/Admin";
const Navigation = () => {
  function mustBeAdmin(route) {
    if (authStore.loggedIn) {
      return route;
    } else {
      return (route.loggedIn || false) === authStore.loggedIn;
    }
  }
  function doNotDisplayInNavigation(route) {
    let checker = route.navDisplay;
    return checker !== false;
  }
  let filteredRoutes = routes
    .filter(mustBeAdmin)
    .filter(doNotDisplayInNavigation);

  filteredRoutes.push(
    authStore.loggedIn === false
      ? { name: "Login", path: "/login" }
      : { name: "Logout", path: "/logout", loggedIn: true }
  );
  if (authStore.profile.type > 0) {
    filteredRoutes.push({
      name: "Admin",
      component: Admin,
      path: "/admin",
      loggedIn: true,
    });
  }
  const size = useWindowSize();
  if (size.width >= 768) {
    return <NavigationBar routes={filteredRoutes} />;
  } else {
    return <NavigationDrawer routes={filteredRoutes} />;
  }
};

export default observer(Navigation);
