import React from "react";
import image from "../../../assets/traditional-door.jpg";
const LoginImageComponent = () => {
  let styles = {
    backgroundImage: `url(${image})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    minHeight: "50vh",
  };
  return <div className="col-12 col-md-3" style={styles}></div>;
};
export default LoginImageComponent;
