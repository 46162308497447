import React from "react";
import { Link } from "react-router-dom";

const MediumView = ({ posts }) => {
  return (
    <div className="row">
      <div className="col-4">
        {posts.map((post, index) => {
          if (index === 1) {
            return (
              <div
                key={index}
                className="pb-3 mb-3"
                style={{ borderBottom: "1px solid #333333" }}
              >
                <Link to={`/magazine/${post.slug}`}>
                  <img
                    alt={post.title}
                    src={post.featured_image}
                    className="mb-3"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                  />
                </Link>
                <h2>
                  <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                </h2>
                <p className="h4">{post.excerpt}</p>
              </div>
            );
          }
          if (5 > index && index > 2) {
            return (
              <div>
                <h2 className="h4 font-weight-bold">
                  <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                </h2>
                <p className="h5">{post.excerpt}</p>
                <hr style={{ border: "1px solid #333333" }} />
              </div>
            );
          } else return null;
        })}
      </div>
      <div className="col-8">
        {posts.map((post, index) => {
          if (index === 0) {
            return (
              <div>
                <Link to={`/magazine/${post.slug}`}>
                  {" "}
                  <img
                    alt={post.title}
                    src={post.featured_image}
                    className="mb-3"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "350px",
                    }}
                  />
                </Link>
                <h1 className="text-center">
                  <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                </h1>
                <p className="h3 text-center">{post.excerpt}</p>
                <hr style={{ border: "1px solid #333333" }} />
              </div>
            );
          }
          if (index === 2) {
            return (
              <div className="row">
                <div className="col-7">
                  <h2 className="h4 font-weight-bold">
                    <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                  </h2>
                  <p className="h5 text-left">{post.excerpt}</p>
                </div>
                <div className="col-5">
                  <Link to={`/magazine/${post.slug}`}>
                    {" "}
                    <img
                      alt={post.title}
                      src={post.featured_image}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "150px",
                      }}
                    />
                  </Link>
                </div>
                <hr style={{ border: "1px solid #333333" }} />
              </div>
            );
          } else return null;
        })}
      </div>
      <div className="col-12">
        <div className="row p-3">
          {posts.map((post, index) => {
            if (index > 4) {
              return (
                <>
                  <div
                    className="col-4 mt"
                    style={index !== 8 ? { borderRight: "1px solid #333" } : {}}
                  >
                    <h2 className="h4 font-weight-bold">
                      <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                    </h2>
                    <p className="h5">{post.excerpt}</p>
                  </div>
                </>
              );
            } else return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default MediumView;
