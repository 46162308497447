import React from "react";
import { routes } from "../../RouteConfig";
import { Link } from "react-router-dom";
export default function FooterLinks() {
  function doNotDisplayInNavigation(route) {
    let checker = route.navDisplay;
    return checker !== false;
  }
  let filteredRoutes = routes.filter(doNotDisplayInNavigation);
  return (
    <div className="col">
      <h6>Popular Pages</h6>
      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        {filteredRoutes.map((route) => (
          <li>
            <Link to={route.path}>{route.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
