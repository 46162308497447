import React, { useContext } from "react";
import { PostContext } from "./Post";
const PostTitle = () => {
  const { post } = useContext(PostContext);
  return (
    <div className="col-12">
      <h2 className="mt-3 font-weight-bold color-black">{post.title}</h2>
    </div>
  );
};

export default PostTitle;
