import React, { useEffect, useContext } from "react";
import { EditorContext } from "./PostEditor";
import adminStore from "../../../../stores/AdminStore";
import { observer } from "mobx-react";
const CategoryComponent = () => {
  const { post, setPost } = useContext(EditorContext);

  useEffect(() => {
    adminStore.category.list();
  }, []);
  return (
    <div className="form-group">
      <label className="h3">Category</label>
      <select
        name="categories"
        value={post.categories}
        onChange={(event) =>
          setPost({ ...post, [event.target.name]: [event.target.value] })
        }
        className="form-control"
      >
        {adminStore.categories.map((item) => (
          <option key={item.slug} value={item.slug}>
            {item.name}
          </option>
        ))}
      </select>
      <div class="invalid-feedback">Please select a proper category.</div>
    </div>
  );
};
export default observer(CategoryComponent);
