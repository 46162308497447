import React, { useContext } from "react";
import { useWindowSize } from "../../utils/customHooks";
import { PostContext } from "./Post";
const PostImage = () => {
  const { post } = useContext(PostContext);
  const size = useWindowSize();
  return (
    <div className="row">
      <img
        src={post.featured_image}
        alt={post.title}
        className="mb-3 img-fluid"
        style={{
          objectFit: "cover",
          objectPosition: "center center",
          width: "100%",
          height: size.width >= 768 ? "500px" : "300px",
        }}
      />
    </div>
  );
};

export default PostImage;
