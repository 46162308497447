import React, { useState, useEffect, useContext, createContext } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";
import { LoadingContext } from "../../components/loading/Loading";
import adminStore from "../../stores/AdminStore";
import PostContainer from "./PostContainer";
import PostImage from "./PostImage";
import PostTitle from "./PostTitle";
import PostExcerpt from "./PostExcerpt";
import PostMeta from "./PostMeta";
import PostContent from "./PostContent";
import PostRelatedPosts from "./PostRelatedPosts";
export const PostContext = createContext();
const GetPost = ({ categorySlug }) => {
  const setLoading = useContext(LoadingContext);
  const { postSlug } = useParams();
  const [post, setPost] = useState({});
  useEffect(() => {
    setLoading(true);
    adminStore.post
      .details(postSlug)
      .then((res) => {
        setPost(res);
      })
      .then(
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      );
  }, [postSlug, setLoading]);
  return (
    <PostContext.Provider value={{ post }}>
      <MetaTags>
        <title>Know Kuwait - {post.title}</title>
      </MetaTags>
      <PostImage />
      <PostContainer>
        <div className="row">
          <PostTitle title={post.title} />
          <PostExcerpt />
          <PostMeta />
          <hr />
          <PostContent />
          <hr />
          <PostRelatedPosts />
        </div>
      </PostContainer>
    </PostContext.Provider>
  );
};
export default GetPost;
