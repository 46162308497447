const data = [
  {
    name: "Basics",
    order: 1,
    description: "Basic stuff about Kuwait",
    articles: [
      {
        title: "Meaning of the word Kuwait",
        slug: "meaning-of-kuwait",
        order: 1,
      },
      {
        title: "Understanding the flag",
        slug: "kuwait-flag",
        order: 2,
      },
      {
        title: "Population of Kuwait",
        slug: "population-of-kuwait",
        order: 3,
      },
      {
        title: "Constitutional structure",
        slug: "constitution-of-kuwait",
        order: 4,
      },
    ],
  },
  {
    name: "Politics of Kuwait",
    order: 1,
    description: "Constitutional powers of Kuwait",
    articles: [
      {
        title: "Executive Branch",
        slug: "government-of-kuwait",
        order: 1,
      },
      {
        title: "Legislative Branch",
        slug: "kuwait-national-assembly",
        order: 2,
      },
      {
        title: "Judicial Branch",
        slug: "courts-of-kuwait",
        order: 3,
      },
      {
        title: "List of Political Organizations",
        slug: "political-organizations-in-Kuwait",
        order: 4,
      },
      {
        title: "Non-Governmental Organizations",
        slug: "kuwait-ngos",
        order: 5,
      },
      {
        title: "Student Organizations",
        slug: "student-movement",
        order: 6,
      },
    ],
  },
];
export default data;
