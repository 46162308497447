import React, { useEffect, useContext } from "react";
import { EditorContext } from "./PostEditor";
const FeaturedImageComponent = () => {
  const { post, setPost } = useContext(EditorContext);
  useEffect(() => {
    const imageBlob = post.featured_image;
    if (imageBlob) {
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      reader.onload = (event) => {
        const imageElement = Object.assign(document.createElement("img"), {
          src: event.target.result,
          className: "mw-100",
        });
        imageElement.onload = () => {
          // get bootstrap row and clean it
          const imagePreviewRow = document.getElementById("imagePreviewRow");
          imagePreviewRow.innerHTML = "";
          // create bootstrap div here
          const imagePreviewDiv = Object.assign(document.createElement("div"), {
            className: "col-12",
            id: "imageDiv",
          });
          imagePreviewDiv.appendChild(imageElement);
          const imageInfo = Object.assign(document.createElement("div"), {
            className: "col-12",
            innerHTML: `
            <span class="d-block">Width: ${imageElement.width}</span>
            <span class="d-block">Height: ${imageElement.height}</span>
            ${
              1200 > imageElement.width
                ? "<span>please upload a larger image</span>"
                : ""
            }`,
          });
          imagePreviewRow.appendChild(imagePreviewDiv);
          imagePreviewRow.appendChild(imageInfo);
        };
      };
    }
  }, [post]);
  return (
    <div className="form-group">
      <label className="h3">Featured Image</label>
      <div className="row" id="imagePreviewRow" />
      <input
        type="file"
        name="featured_image"
        id="image"
        accept="image/png, image/jpeg"
        onChange={(event) =>
          setPost({ ...post, [event.target.name]: event.target.files[0] })
        }
        className="d-block mt-3"
      />
    </div>
  );
};
export default FeaturedImageComponent;
