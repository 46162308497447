import React, { useContext } from "react";
import { PostContext } from "./Post";
const PostContent = () => {
  const { post } = useContext(PostContext);
  return (
    <div
      className="col-12"
      dangerouslySetInnerHTML={{ __html: post.content }}
    />
  );
};

export default PostContent;
