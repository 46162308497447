import React, { useContext } from "react";
import { PostContext } from "./Post";
const PostExcerpt = () => {
  const { post } = useContext(PostContext);
  return (
    <div className="col-12">
      <h3 className="h4 color-black d-none d-md-block">{post.excerpt}</h3>
    </div>
  );
};

export default PostExcerpt;
