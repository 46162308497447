import React, { useContext } from "react";
import authStore from "../../stores/AuthStore";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { PostContext } from "./Post";

const PostMeta = () => {
  const { post } = useContext(PostContext);
  return (
    <div className="col-12">
      <p className="small">
        <span className="d-block">
          Written by {post.user && <>{post.user.full_name}</>}
        </span>
        <span className="d-block">
          Published on {new Date(post.publish_date).toLocaleDateString()}
        </span>
        {authStore.profile.type > 1 && (
          <span className="d-block">
            <Link
              style={{ color: "red" }}
              to={`/admin/posts/edit/${post.slug}`}
            >
              edit
            </Link>
          </span>
        )}
      </p>
    </div>
  );
};

export default observer(PostMeta);
