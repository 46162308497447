import React, { useEffect, useContext, useState, useRef } from "react";
import { EditorContext } from "./PostEditor";
import slugify from "../../../../utils/slugify";
import { debounce } from "lodash";
const SlugComponent = () => {
  const [edit, setEdit] = useState(false);
  const { post, setPost } = useContext(EditorContext);
  if (edit) {
    return (
      <div className="form-group mt-3">
        <input
          onChange={(event) =>
            setPost({
              ...post,
              [event.target.name]: slugify(event.target.value),
            })
          }
          autoComplete="off"
          value={post.slug}
          className=" small"
          name="slug"
          maxLength="50"
        />
        <button
          type="button"
          onClick={() => setEdit(false)}
          className="btn btn-light btn-sm d-inline"
        >
          done
        </button>
      </div>
    );
  } else {
    return (
      <div className="form-group mt-3">
        <p className="small d-inline">{`https://knowkuwait.com/${
          post.categories
        }/${post.slug || ""}`}</p>
        <button
          type="button"
          onClick={() => setEdit(true)}
          className="btn btn-light btn-sm d-inline"
        >
          edit
        </button>
      </div>
    );
  }
};
export default SlugComponent;
