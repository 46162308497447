import React from "react";
import formatDate from "../../utils/formatDate";
import { useWindowSize } from "../../utils/customHooks";
const TickerComponent = ({ type, data }) => {
  const size = useWindowSize();
  if (size.width > 768) {
    return (
      <div className="col h6">
        <div className="row">
          <div className="col-12 font-weight-bold">{type}</div>
        </div>
        <div className="row">
          <div className="col-5">Price</div>
          <div className="col-5">{data.price}</div>
        </div>
        <div className="row">
          <div className="col-5">last update</div>
          <div className="col-5">{formatDate(data.last_update)}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="col-12 col-md-2 h6">
        <div className="row">
          <div className="col-5 font-weight-bold">{type}</div>
          <div className="col-3">{data.price}</div>
          <div className="col-4">{formatDate(data.last_update)}</div>
        </div>
      </div>
    );
  }
};

export default TickerComponent;
