import React, { useContext } from "react";
import { UserContext } from "./UserEditor";
const PasswordComponent = () => {
  const { form, setForm } = useContext(UserContext);

  return (
    <div className="form-group">
      <label className="h3" htmlFor="password">
        Password
      </label>
      <input
        className="form-control"
        name="password"
        value={form.password}
        onChange={(event) =>
          setForm({
            ...form,
            [event.target.name]: event.target.value,
          })
        }
      />
    </div>
  );
};

export default PasswordComponent;
