import React from "react";
const ContactSubmtiComponent = () => {
  return (
    <div className="col-12">
      <button type="submit" className="btn btn-primary form-control">
        Send
      </button>
    </div>
  );
};

export default ContactSubmtiComponent;
