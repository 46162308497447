import React from "react";
import notFoundImage from "./assets/kuwait-water-towers.jpg";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
const NotFound = () => {
  return (
    <>
      <MetaTags>
        <title>404 Error</title>
      </MetaTags>
      <div
        className="row"
        style={{
          backgroundImage: `url(${notFoundImage})`,
          backgroundPosition: "cover",
          minHeight: "500px"
        }}
      >
        <div className="col-12">
          <span
            style={{
              position: "absolute",
              top: "10%",
              left: "5%",
              maxWidth: "90%"
            }}
          >
            <h1 className="bg-desert p-2">404 Error</h1>
          </span>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <h2>
            The page you're looking for seems missing.
            <Link to="/contact"> Contact us</Link> if you think there's an
            error.
          </h2>
        </div>
      </div>
    </>
  );
};

export default NotFound;
