import React from "react";
export default function FooterInfo() {
  return (
    <div className="col">
      <h6>About KnowKuwait</h6>
      <p className="small">
        KnowKuwait is an independent media corporation that promotes the State
        of Kuwait to the world. KnowKuwait is not affiliated in any way or form
        with the Government of Kuwait.
      </p>
    </div>
  );
}
