import React, { createContext } from "react";
import Navigation from "./components/navigation/Navigation";
import { AlertProvider } from "./components/alert/Alert";
import RouteConfig from "./RouteConfig";
import { LoadingProvider } from "./components/loading/Loading";
import Footer from "./components/footer/Footer";
export const UserContext = createContext();
function App() {
  return (
    <>
      <LoadingProvider>
        <AlertProvider>
          <div className="container-fluid">
            <Navigation />
            <RouteConfig />
            <Footer />
          </div>
        </AlertProvider>
      </LoadingProvider>
    </>
  );
}
export default App;
