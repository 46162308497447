import React, { useState, useEffect, useContext } from "react";
import adminStore from "../../stores/AdminStore";
import { observer } from "mobx-react";
import { AlertContext } from "../../components/alert/Alert";

const ManageCategories = () => {
  const setAlert = useContext(AlertContext);
  useEffect(() => {
    adminStore.category.list();
  }, []);
  const AddCategory = () => {
    const [value, setValue] = useState();
    const [edit, setEdit] = useState(false);
    const handleSubmit = (e) => {
      e.preventDefault();
      adminStore.category
        .new(value)
        .then((res) => {
          setAlert("new category created successfully.");
        })
        .catch((e) => setAlert(e.message));
    };

    if (!edit) {
      return <span onClick={() => setEdit(!edit)}>Add New Category</span>;
    } else {
      return (
        <form onSubmit={handleSubmit}>
          <label>name</label>
          <input
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <button value="Submit">Submit</button>
        </form>
      );
    }
  };

  const CategoryName = ({ name }) => {
    const [value, setValue] = useState(name);
    const [edit, setEdit] = useState(false);
    const handleChange = (e) => {
      setValue(e.target.value);
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      adminStore.category
        .edit(name, value)
        .then((res) => {
          setAlert("updated successfully.");
        })
        .catch((e) => setAlert(e.message));
    };
    if (!edit) {
      return (
        <div>
          <span>{value}</span>
          <span style={{ marginLeft: "10px" }} onClick={() => setEdit(true)}>
            Edit
          </span>
          <span
            style={{ marginLeft: "10px" }}
            onClick={() => adminStore.category.delete(value)}
          >
            Delete
          </span>
        </div>
      );
    } else {
      return (
        <form onSubmit={handleSubmit}>
          <input value={value} onChange={handleChange} />
          <button value="Submit">Submit</button>
        </form>
      );
    }
  };

  return (
    <div className="container">
      <AddCategory />
      <br />
      {adminStore.categories.map((item) => (
        <CategoryName name={item.name} />
      ))}
    </div>
  );
};
export default observer(ManageCategories);
