import React, { useContext } from "react";
import { UserContext } from "./UserEditor";
const FullNameComponent = () => {
  const { form, setForm } = useContext(UserContext);

  return (
    <div className="form-group">
      <label className="h3" htmlFor="full_name">
        Full Name
      </label>
      <input
        autoComplete="off"
        className="form-control"
        name="full_name"
        value={form.full_name}
        onChange={(event) =>
          setForm({
            ...form,
            [event.target.name]: event.target.value,
          })
        }
      />
    </div>
  );
};

export default FullNameComponent;
