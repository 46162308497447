import React, { useState } from "react";
import PostTableRowDeleteModal from "./PostTableRowDeleteModal";
const PostTableRowActionsComponent = () => {
  const [value, setValue] = useState();
  const [showModal, setShowModal] = useState(false);
  const handleChange = (event) => {
    if (event.target.value === "DELETE") {
      setShowModal(true);
    }
  };
  return (
    <>
      <PostTableRowDeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        setValue={setValue}
      />
      <td>
        <select
          name="actions"
          value={value}
          className="form-control"
          onChange={handleChange}
        >
          <option value="0" selected>
            ---
          </option>
          <option value="DELETE">delete</option>
        </select>
      </td>
    </>
  );
};
export default PostTableRowActionsComponent;
