import React, { useContext } from "react";
import { EditorContext } from "./PostEditor";
import SlugComponent from "./SlugComponent";
import slugify from "../../../../utils/slugify";
const TitleComponent = () => {
  const { post, setPost } = useContext(EditorContext);
  return (
    <div className="form-group">
      <label className="h3">Title</label>
      <input
        autoComplete="off"
        className="form-control"
        type="text"
        name="title"
        maxLength="255"
        value={post.title}
        onChange={(event) =>
          setPost({
            ...post,
            [event.target.name]: event.target.value,
            slug: slugify(event.target.value),
          })
        }
      />
      <SlugComponent />
      <div class="invalid-feedback">Use a title at least five words.</div>
    </div>
  );
};
export default TitleComponent;
