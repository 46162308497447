import React, { useEffect, useContext } from "react";
import { EditorContext } from "./PostEditor";
import adminStore from "../../../../stores/AdminStore";
import { observer } from "mobx-react";
import authStore from "../../../../stores/AuthStore";
const UserComponent = () => {
  const { post, setPost } = useContext(EditorContext);

  useEffect(() => {
    setPost({ ...post, user: authStore.profile.id });
  }, [authStore.profile.id]);

  useEffect(() => {
    adminStore.user.list();
  }, [adminStore.user.list]);

  return (
    <div className="form-group">
      <label className="h3">Author</label>
      <select
        name="user"
        value={post.user}
        onChange={(event) =>
          setPost({
            ...post,
            [event.target.name]: event.target.value,
          })
        }
        className="form-control"
      >
        {adminStore.users.map((item) => (
          <option key={item.full_name} value={item.id}>
            {item.full_name}
          </option>
        ))}
      </select>
    </div>
  );
};
export default observer(UserComponent);
