import React, { useState, useEffect, createContext } from "react";
import { Routes, useNavigate, Route, Outlet } from "react-router-dom";
import authStore from "../stores/AuthStore";

export const AdminContext = createContext();
const Admin = () => {
  console.log("here");
  const navigate = useNavigate();
  useEffect(() => {
    if (!authStore.loggedIn) {
      navigate(`/login`, { replace: true });
    }
    if (1 > authStore.profile.type) {
      navigate(`/`, { replace: true });
    }
  }, [navigate]);
  return <Outlet />;
};
export default Admin;
