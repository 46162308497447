import React, { useEffect, useContext, useState } from "react";
import { EditorContext } from "./PostEditor";
import slugify from "../../../../utils/slugify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
const TagComponent = () => {
  const { post, setPost } = useContext(EditorContext);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setPost({ ...post, tags: [...post.tags, slugify(event.target.value)] });
      event.target.value = "";
    }
  };
  const removeTag = (tag) => {
    const filteredTags = post.tags.filter((item) => item !== tag);
    setPost({ ...post, tags: filteredTags });
  };
  return (
    <div className="form-group">
      <label className="h3">Tags</label>
      <input
        autoComplete="off"
        className="form-control"
        type="text"
        name="tags"
        onKeyPress={handleKeyPress}
      />
      <div className="mt-2">
        {post.tags &&
          post.tags.map((tag) => (
            <span
              onClick={() => removeTag(tag)}
              className="p-2 mr-1 mb-1 font-weight-normal badge badge-pill badge-primary"
            >
              {tag}
              <FontAwesomeIcon icon={faTimesCircle} className="ml-1" />
            </span>
          ))}
      </div>
      <div class="invalid-feedback">Please enter at least three tags.</div>
    </div>
  );
};
export default TagComponent;
