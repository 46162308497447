import React, { useEffect, useRef, createContext, useState } from "react";
import styled from "styled-components";
import { useDebounce } from "../../utils/customHooks";

export const AlertContext = createContext();
export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState();
  return (
    <>
      <Alert setAlert={setAlert} message={alert} />
      <AlertContext.Provider value={setAlert}>{children}</AlertContext.Provider>
    </>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 30px;
  font-size: 1.2em;
  text-align: center;
  background: ${(props) =>
    props.type === "success" ? "aquamarine" : `tomato`};
`;

const Alert = ({ setAlert, message, type }) => {
  const debouncedMessagee = useDebounce(message, 1000);
  const alert = useRef();
  useEffect(() => {
    if (debouncedMessagee) {
      let timer = setTimeout(() => {
        setAlert(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [setAlert, debouncedMessagee]);
  if (debouncedMessagee) {
    return (
      <StyledDiv ref={alert} type={type} id="alert">
        {debouncedMessagee}
      </StyledDiv>
    );
  } else return null;
};
export default Alert;
