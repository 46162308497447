import React, { useEffect, createContext } from "react";
import adminStore from "../../stores/AdminStore";
import { observer } from "mobx-react";
import PostTable from "./table/PostTable";
import { Link } from "react-router-dom";
export const ManagePostsContext = createContext();
const ManagePosts = () => {
  useEffect(() => {
    adminStore.post.adminList();
    adminStore.user.list();
    adminStore.category.list();
  }, []);
  return (
    <div>
      <button className="btn btn-primary m-5">
        <Link to="/admin/posts/new">New Post</Link>
      </button>
      <ManagePostsContext.Provider
        value={{
          users: adminStore.users,
          posts: adminStore.posts,
          categories: adminStore.categories,
        }}
      >
        <PostTable />
      </ManagePostsContext.Provider>
    </div>
  );
};
export default observer(ManagePosts);
