import React, { useState, useEffect, createContext } from "react";
import { Routes, useNavigate, Route, Outlet, Link } from "react-router-dom";
import kuwaitAirwaysImage from "../assets/kuwait-airways-plane.jpg";
import data from "./data";
const Guide = () => {
  return (
    <div className="row">
      <div
        className="col-12"
        style={{
          minHeight: "50vh",
          backgroundImage: `url(${kuwaitAirwaysImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "10%",
            left: "5%",
            maxWidth: "90%",
          }}
        >
          <h1 className="bg-desert p-2">Unofficial Guide of Kuwait</h1>
        </span>
      </div>
      <div className="container mt-5">
        {data.map((section) => (
          <div className="row mb-5">
            <div className="col-12">
              <h5>{section.name}</h5>
              <hr />
            </div>

            {section.articles.map((article, index) => (
              <div className="col-12 col-md-6 col-lg-4 mb-1">
                <Link className="color-blue" to={`/guide/${article.slug}`}>
                  <span className="mr-3">{index + 1}.</span> {article.title}
                </Link>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Guide;
