import React, { useContext } from "react";
import Modal from "react-modal";
import adminStore from "../../../stores/AdminStore";
import { PostTableRowContext } from "./PostTableRow";
const PostTableRowDeleteModal = ({ setValue, showModal, setShowModal }) => {
  const { post } = useContext(PostTableRowContext);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "5%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal
      style={customStyles}
      isOpen={showModal}
      contentLabel="Minimal Modal Example"
    >
      <h4>are you sure you want to delete {post.title} ?</h4>
      <div className="text-center">
        <button
          onClick={() => {
            adminStore.post.delete(post.slug);
            setShowModal(false);
          }}
          className="mr-5 btn btn-danger btn-block"
        >
          Confirm
        </button>
        <button
          className="btn btn-primary btn-block"
          onClick={() => {
            setShowModal(false);
            setValue(0);
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
export default PostTableRowDeleteModal;
