import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authStore from "../../../stores/AuthStore";
import { observer } from "mobx-react";
const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (authStore.loggedIn) {
      authStore.logout();
      navigate("/", { replace: true });
    }
  }, [navigate]);
  return <div></div>;
};
export default observer(Login);
