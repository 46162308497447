import React, { useState, useEffect, useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";
import authStore from "../../../stores/AuthStore";
import { observer } from "mobx-react";
import { AlertContext } from "../../../components/alert/Alert";
import EmailInputComonent from "./EmailInputComponent";
import PasswordInputComponent from "./PasswordInputComponent";
import LoginSubmitButton from "./LoginSubmitButton";
import LoginImageComponent from "./LoginImageComponent";
export const LoginContext = createContext();

const Login = () => {
  const [form, setForm] = useState({ email: "", password: "" });
  const setAlert = useContext(AlertContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (authStore.loggedIn) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    authStore
      .login(form)
      .then((res) => {
        try {
          navigate.goBack();
        } catch {
          navigate("/admin", { replace: true });
        }
      })
      .catch((e) => setAlert(e.message));
  };
  return (
    <LoginContext.Provider value={{ form, setForm }}>
      <div className="row min-vh-100">
        <LoginImageComponent />
        <div className="col-12 col-md-6 col-lg-3 mx-auto form-group my-5">
          <form onSubmit={handleSubmit}>
            <EmailInputComonent />
            <PasswordInputComponent />
            <LoginSubmitButton />
          </form>
        </div>
      </div>
    </LoginContext.Provider>
  );
};
export default observer(Login);
