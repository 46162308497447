import React, { useEffect, useContext, useState } from "react";
import { PostTableRowContext } from "./PostTableRow";
import { instance } from "../../../stores/AuthStore";
import { ManagePostsContext } from "../ManagePosts";

const PostTableRowCategoryComponent = () => {
  const { post } = useContext(PostTableRowContext);
  const { categories } = useContext(ManagePostsContext);
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(post.categories);
  }, [post.categories]);

  const handleChange = (event) => {
    instance
      .post(`/blog/test/add_categories/`, {
        post_slug: post.slug,
        categories: [event.target.value],
      })
      .then((res) => {
        setValue(res.data.categories);
        console.log(value);
      });
  };

  return (
    <td>
      <select
        name="categories"
        value={value && value[0]}
        className="form-control"
        onChange={handleChange}
      >
        {categories &&
          categories.map((item) => (
            <option key={item.slug} value={item.slug}>
              {item.name}
            </option>
          ))}
      </select>
    </td>
  );
};
export default PostTableRowCategoryComponent;
