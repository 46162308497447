import React, { useContext, useEffect } from "react";
import { UserContext } from "./UserEditor";

const TypeComponent = () => {
  const { form, setForm } = useContext(UserContext);
  useEffect(() => {
    setForm({ ...form, type: 0 });
  }, []);
  return (
    <div className="form-group">
      <label className="h3" htmlFor="type">
        Account Type
      </label>
      <select
        name="type"
        value={form.type}
        onChange={(event) =>
          setForm({ ...form, [event.target.name]: event.target.value })
        }
        className="form-control"
      >
        <option value={parseInt(0)}>Normal</option>
        <option value={parseInt(1)}>Staff</option>
        <option value={parseInt(2)}>Admin</option>
      </select>
    </div>
  );
};

export default TypeComponent;
