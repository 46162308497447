import React, { useEffect, useContext, useState } from "react";
import { LoginContext } from "./Login";
const LoginSubmitButton = () => {
  const { form } = useContext(LoginContext);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (form.email && form.password) {
      setDisabled(false);
    }
  }, [form]);
  return (
    <div className="form-group">
      <button
        disabled={disabled}
        type="submit"
        className="btn btn-primary mt-3 form-control"
      >
        Login
      </button>
    </div>
  );
};
export default LoginSubmitButton;
