import React from "react";
import image from "../../assets/kuwait-liberation-tower.jpg";
const ContactImageComponent = () => {
  let styles = {
    minHeight: "50vh",
    backgroundImage: `url(${image})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };
  return <div className="col-12 col-md-3" style={styles}></div>;
};
export default ContactImageComponent;
