import React from "react";
import { Link } from "react-router-dom";

const SmallView = ({ posts }) => {
  return (
    <>
      {posts.map((post, index) =>
        index === 0 ? (
          <div key={post.id}>
            <Link to={`/magazine/${post.slug}`}>
              <div className="row mt-3">
                <div className="col-6">
                  <img
                    src={post.featured_image}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "150px",
                    }}
                    alt={post.title}
                  />
                </div>
                <div
                  className="col-6"
                  style={{ marginLeft: 0, paddingLeft: 0 }}
                >
                  <h2 className="h3 color-black font-weight-bold">
                    {post.title}
                  </h2>
                </div>
              </div>
            </Link>
            <hr />
          </div>
        ) : (
          <div key={post.id}>
            <Link to={`/magazine/${post.slug}`}>
              <div className="row mt-3">
                <div className="col-4">
                  <img
                    src={post.featured_image}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100px",
                    }}
                    alt={post.title}
                  />
                </div>
                <div
                  className="col-8"
                  style={{ marginLeft: 0, paddingLeft: 0 }}
                >
                  <h3 className="h5 color-black d-inline font-weight-bold">
                    {post.title}
                  </h3>
                </div>
              </div>
            </Link>
            <hr />
          </div>
        )
      )}
    </>
  );
};

export default SmallView;
