import React from "react";
import { Routes, Route, useParams, Outlet } from "react-router";
import Category from "./categories/Category";
import Post from "./posts/Post";
import Feed from "./feed/Feed";
const Blog = () => {
  console.log("JERE");
  return <Outlet />;
};
export default Blog;
