import React from "react";

const ContactFormSuccess = () => {
  return (
    <div className="row pt-3">
      <div className="offset-md-3 col-md-6">
        <p>Thank you for contacting us.</p>
      </div>
    </div>
  );
};

export default ContactFormSuccess;
