import React, { useEffect } from "react";
import adminStore from "../../stores/AdminStore";
import { observer } from "mobx-react";

const Category = ({ slug }) => {
  useEffect(() => {
    adminStore.category.list(slug);
  }, []);
  return (
    <>
      {adminStore.posts.map((post) => (
        <p>{post.title}</p>
      ))}
    </>
  );
};
export default observer(Category);
