import React, { useContext } from "react";
import PostTableRow from "./PostTableRow";
import PostTableHead from "./PostTableHead";
import { ManagePostsContext } from "../ManagePosts";

const PostTable = () => {
  const { posts } = useContext(ManagePostsContext);
  return (
    <table className="table">
      <PostTableHead />
      <tbody>
        {posts.map((post, index) => (
          <PostTableRow index={index} post={post} />
        ))}
      </tbody>
    </table>
  );
};
export default PostTable;
