import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/notfound/NotFound";

// eslint-disable-next-line no-unused-vars
import Home from "./home/Home";
import Admin from "./admin/Admin";
import Login from "./admin/users/login/Login";
import Logout from "./admin/users/logout/Logout";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Magazine from "./magazine/Magazine";
import Post from "./magazine/posts/Post";
import Feed from "./magazine/feed/Feed";

import ManagePosts from "./admin/posts/ManagePosts";
import Dashboard from "./admin/Dashboard";
import ManageCategories from "./admin/categories/ManageCategories";
import ManageUsers from "./admin/users/ManageUsers";
import UserEditor from "./admin/users/editor/UserEditor";
import NewPost from "./admin/posts/editor/new/PostEditor";
import EditPost from "./admin/posts/editor/edit/PostEditor";
import Guide from "./guide/Guide";

export const routes = [
  { name: "Home", path: "/" },
  { name: "About", path: "/about" },
  { name: "Magazine", path: "/magazine" },
  { name: "Contact", path: "/contact" },
];

export default function RouteConfig() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="magazine" element={<Magazine />}>
        <Route path="/" element={<Feed />} />
        <Route path="/:postSlug" element={<Post />} />
      </Route>
      <Route path="admin" element={<Admin />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/posts/new" element={<NewPost />} />
        <Route path="/posts/edit/:slug" element={<EditPost />} />
        <Route path="/posts" element={<ManagePosts />} />
        <Route path="/categories" element={<ManageCategories />} />
        <Route path="/users" element={<ManageUsers />} />
        <Route path="/users/new" element={<UserEditor />} />
      </Route>
      <Route path="logout" element={<Logout />} />
      <Route path="login" element={<Login />} />
      <Route path="guide" element={<Guide />} />
      <Route element={<NotFound />} />
    </Routes>
  );
}
