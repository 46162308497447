import React, { useContext } from "react";
import { EditorContext } from "./PostEditor";
import { Editor } from "@tinymce/tinymce-react";
import { instance } from "../../../../stores/AuthStore";
const ContentComponent = () => {
  const { post, setPost } = useContext(EditorContext);
  return (
    <div className="form-group">
      <label className="h3">Content</label>
      <Editor
        apiKey="62wd8giv6jyismeqj1khdrlrrjqeajfh2nqua2g0tusba0fq"
        textareaName="myTextArea"
        value={post.content}
        init={{
          images_upload_handler: function (blobInfo, success, failure) {
            let payload = new FormData();
            payload.append("image", blobInfo.blob(), blobInfo.filename());
            instance
              .post("/blog/image-upload/", payload, {
                headers: {
                  "content-type": `multipart/form-data`,
                },
              })
              .then((res) => success(res.data.location))
              .catch((e) => failure(e));
          },

          height: 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste imagetools wordcount",
          ],
          name: "content",
          toolbar:
            "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
        }}
        onEditorChange={(content) => setPost({ ...post, content: content })}
        name="content"
        id="content"
      />
    </div>
  );
};
export default ContentComponent;
