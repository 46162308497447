import React, { useEffect, useContext, useState } from "react";
import { PostTableRowContext } from "./PostTableRow";
import { instance } from "../../../stores/AuthStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
const PostTableRowTagComponent = () => {
  const { post } = useContext(PostTableRowContext);
  const [value, setValue] = useState([]);

  useEffect(() => {
    setValue(post.tags);
  }, [post.tags]);
  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     setTags([...tags, slugify(event.target.value)]);

  //     event.target.value = "";
  //   }
  // };
  const removeTag = (tag) => {
    const filteredTags = value.filter((item) => item !== tag);
    setValue(filteredTags);
  };

  const handleChange = (event) => {
    instance
      .post(`/blog/test/add_categories/`, {
        post_slug: post.slug,
        categories: [event.target.value],
      })
      .then((res) => {
        setValue(res.data.categories);
        console.log(value);
      });
  };

  return (
    <>
      <td>
        {value.map((tag) => (
          <span
            onClick={() => removeTag(tag)}
            className="p-2 mr-1 mb-1 font-weight-normal badge badge-pill badge-primary"
          >
            {tag.slug}
            <FontAwesomeIcon icon={faTimesCircle} className="ml-1" />
          </span>
        ))}
      </td>
    </>
  );
};
export default PostTableRowTagComponent;
