import { decorate, observable } from "mobx";
import jwt_decode from "jwt-decode";
import axios from "axios";

export const instance = axios.create({ baseURL: "https://muffinbase.com/" });
class AuthStore {
  loggedIn = false;
  profile = {};

  signup = (form) =>
    new Promise((resolve, reject) => {
      instance
        .post("users/create/", form)
        .then((res) => {
          this.setToken(res.data.access).then((res) => resolve(res));
        })
        .catch((e) => reject(e));
    });
  fetchProfile = () =>
    new Promise((resolve, reject) => {
      if (Object.keys(this.profile) > 0) {
        resolve(this.profile);
      } else {
        instance
          .get("users/details/")
          .then((res) => {
            this.profile = res.data;
            resolve(res);
          })
          .catch((e) => reject(e));
      }
    });

  setToken = (token) =>
    new Promise((resolve, reject) => {
      if (token) {
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        localStorage.setItem("knowKuwait", token);
        this.loggedIn = true;
        this.fetchProfile()
          .then((res) => {
            resolve(res);
          })
          .catch((e) => reject(e));
      } else {
        try {
          this.loggedIn = false;
          this.profile = {};
          delete instance.defaults.headers.common["Authorization"];
          localStorage.removeItem("knowKuwait");
          resolve();
        } catch (e) {
          reject(e);
        }
      }
    });
  login = (form) =>
    new Promise((resolve, reject) => {
      instance
        .post("users/login/", form)
        .then((res) => {
          this.setToken(res.data.access).then((res) => resolve(res));
        })
        .catch((e) => reject(e));
    });
  logout = () => {
    this.setToken();
  };
  checkForToken = () => {
    const token = localStorage.getItem("knowKuwait");
    if (token) {
      const currentTime = Date.now() / 1000;
      const jwt = jwt_decode(token);
      if (jwt.exp >= currentTime) {
        this.setToken(token);
      } else {
        this.logout();
      }
    }
  };
  verifyEmail = (token) =>
    new Promise((resolve, reject) => {
      instance.get(`users/email/verify/${token}/`).then((res) => {
        this.setToken(res.data.access)
          .then((res) => resolve(res))
          .catch((e) => reject(e));
      });
    });
  resetPassword = (email) =>
    new Promise((resolve, reject) => {
      const payload = { email: email };
      instance
        .post(`users/password/reset/`, payload)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  setNewPassword = (token, password) =>
    new Promise((resolve, reject) => {
      const payload = { new_password: password };
      instance
        .post(`users/password/reset/${token}/`, payload)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  changePassword = (form) =>
    new Promise((resolve, reject) => {
      instance
        .put(`users/password/change/`, form)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
}

decorate(AuthStore, {
  loggedIn: observable,
  profile: observable,
});

const authStore = new AuthStore();
authStore.checkForToken();

export default authStore;
