import React from "react";
import { Link } from "react-router-dom";
const LargeView = ({ posts }) => {
  return (
    <div className="row mainPage">
      {}
      <div className="col-3" id="right">
        {posts.map((post, index) => {
          if (index === 1) {
            return (
              <div
                key={index}
                className="pb-3 mb-3"
                style={{ borderBottom: "1px solid #333333" }}
              >
                <Link to={`/magazine/${post.slug}`}>
                  <img
                    alt={post.title}
                    src={post.featured_image}
                    className="mb-3"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center center",
                      width: "100%",
                      height: "150px",
                    }}
                  />
                </Link>
                <h2>
                  <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                </h2>
                <p className="h4">{post.excerpt}</p>
              </div>
            );
          }
          if (index > 3 && 6 > index) {
            return (
              <div
                key={index}
                className="pb-3 mb-3"
                style={{ borderBottom: "1px solid #333333" }}
              >
                <h2>
                  <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                </h2>
                <p className="h4">{post.excerpt}</p>
              </div>
            );
          } else return null;
        })}
      </div>
      <div className="col-6">
        {posts.map((post, index) => {
          if (index === 0) {
            return (
              <div
                key={index}
                className="mb-3 pb-3"
                style={{ borderBottom: "1px solid #333333" }}
              >
                <Link to={`/magazine/${post.slug}`}>
                  <img
                    alt={post.title}
                    src={post.featured_image}
                    className="mb-3"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "350px",
                    }}
                  />
                </Link>
                <h1 className="text-center">
                  <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                </h1>
                <p className="h3 text-center">{post.excerpt}</p>
              </div>
            );
          }
          if (index === 3) {
            return (
              <div key={index} className="row pb-5">
                <div className="col-8">
                  <h2>
                    <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                  </h2>
                  <p className="h4 text-left">{post.excerpt}</p>
                </div>
                <div className="col-4">
                  <img
                    alt={post.title}
                    src={post.featured_image}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
            );
          } else return null;
        })}
      </div>
      <div className="col-3">
        {posts.map((post, index) => {
          if (index === 2) {
            return (
              <div
                key={index}
                className="pb-3 mb-3"
                style={{ borderBottom: "1px solid #333333" }}
              >
                <Link to={`/magazine/${post.slug}`}>
                  <img
                    src={post.featured_image}
                    alt={post.title}
                    className="mb-3"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center center",
                      width: "100%",
                      height: "150px",
                    }}
                  />
                </Link>
                <h2>
                  <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                </h2>
                <p className="h4">{post.excerpt}</p>
              </div>
            );
          }
          if (index > 5) {
            return (
              <div
                key={index}
                className="pb-3 mb-3"
                style={{ borderBottom: "1px solid #333333" }}
              >
                <h2>
                  <Link to={`/magazine/${post.slug}`}>{post.title}</Link>
                </h2>
                <p className="h4">{post.excerpt}</p>
              </div>
            );
          } else return null;
        })}
      </div>
    </div>
  );
};

export default LargeView;
