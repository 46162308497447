import React, { useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import openImg from "./assets/open-button.svg";
import closeImg from "./assets/close-button.svg";
import styled from "styled-components";
import logo from "../../logo.svg";

// Components
const StyledNavigationBar = styled.div`
  width: 100%;
  height: 75px;
  text-align: center;
`;
const StyledLogoContainer = styled.div`
  width: auto;
  height: 100%;
  float: left;
`;
const StyledLogo = styled.img`
  height: 100%;
  width: auto;
  padding: 10px;
`;
const StyledButtonContainer = styled.div`
  width: auto;
  height: 100%;
  float: right;
`;
const StyledOpenButton = styled.button`
  background: none;
  border: 0;
  float: right;
  margin: 25px 15px 0 0;
`;
const StyledImg = styled.img`
  width: 25px;
  height: 25px;
`;
const StyledNavigationDrawer = styled.div`
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #0c59bc;
  transition: all 1s ease;
  z-index: 1000;
  border-right: 2px #ff7e51 solid;
`;
const activeClassName = "bg-gold";
const StyledLink = styled(NavLink).attrs({
  activeClassName,
})`
  &.${activeClassName} {
    text-decoration: underline;
  }
  text-align: right;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1em;
  list-style: none;
  text-decoration: none;
  color: #fff;
`;
const StyledUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const StyledLi = styled.li`
  width: 100%;
  height: 50px;
  text-align: center;
  color: #fff;
`;
const StyledCloseButton = styled.button`
  font-size: 1em;
  background: none;
  border: 0;
  text-align: center;
  margin: 2vh 0 0 0;
`;
const StyledDiv = styled.div`
  width: 100%;
  height: 25px;
  text-align: center;
  margin: 20px 0 100px 0;
`;
const NavigationDrawer = ({ routes }) => {
  const drawer = useRef();
  const navigate = useNavigate();

  const NavigationLogo = () => {
    const handleLogoClick = () => {
      navigate("/");
    };
    return (
      <StyledLogoContainer onClick={handleLogoClick}>
        <StyledLogo src={logo} />
      </StyledLogoContainer>
    );
  };
  const OpenButton = ({ drawer }) => {
    const handleOpenClick = () => {
      drawer.current.style.left = "0";
    };
    return (
      <StyledButtonContainer>
        <StyledOpenButton onClick={handleOpenClick}>
          <StyledImg src={openImg} />
        </StyledOpenButton>
      </StyledButtonContainer>
    );
  };
  const NavigationLinks = ({ routes, drawer }) => {
    const handleLinkClick = () => {
      drawer.current.style.left = "-100%";
    };
    return (
      <StyledUl>
        {routes.map((route) => (
          <StyledLi key={route.name}>
            <StyledLink onClick={handleLinkClick} to={route.path}>
              {route.name}
            </StyledLink>
          </StyledLi>
        ))}
      </StyledUl>
    );
  };
  const CloseButton = ({ drawer }) => {
    const handleCloseClick = () => {
      drawer.current.style.left = "-100%";
    };
    return (
      <StyledDiv>
        <StyledCloseButton onClick={handleCloseClick}>
          <StyledImg src={closeImg} />
        </StyledCloseButton>
      </StyledDiv>
    );
  };
  return (
    <>
      <StyledNavigationBar>
        <NavigationLogo />
        <OpenButton drawer={drawer} />
      </StyledNavigationBar>
      <StyledNavigationDrawer ref={drawer}>
        <CloseButton drawer={drawer} />
        <NavigationLinks drawer={drawer} routes={routes} />
      </StyledNavigationDrawer>
    </>
  );
};

export default NavigationDrawer;
