import React, { useContext } from "react";
import { PostTableRowContext } from "./PostTableRow";
import { Link } from "react-router-dom";

const PostTableRowTitleComponent = () => {
  const { post } = useContext(PostTableRowContext);

  return (
    <td>
      <Link to={`/admin/posts/edit/${post.slug}`}>{post.title}</Link>
    </td>
  );
};
export default PostTableRowTitleComponent;
