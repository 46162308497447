import React, { useContext } from "react";
import { EditorContext } from "./PostEditor";

const ExcerptComponent = () => {
  const { view, post, setPost } = useContext(EditorContext);
  return (
    <div className="form-group">
      <label className="h3">Excerpt</label>
      <input
        autoComplete="off"
        className="form-control"
        type="text"
        name="excerpt"
        value={post.excerpt}
        onChange={(event) =>
          setPost({
            ...post,
            [event.target.name]: event.target.value,
          })
        }
      />
    </div>
  );
};
export default ExcerptComponent;
