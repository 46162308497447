import React from "react";
const ContactUpperMessage = () => {
  return (
    <div className="col-12 pt-3">
      <p>
        Leave your contact information below and we will get back to you ASAP.
      </p>
    </div>
  );
};

export default ContactUpperMessage;
