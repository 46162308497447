import React, { useEffect, useContext, useState } from "react";
import { EditorContext } from "./PostEditor";
const SubmitButtonComponent = () => {
  const { post, setPost } = useContext(EditorContext);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (post.title && post.excerpt && post.content) {
      setDisabled(false);
    }
  }, [post]);
  return (
    <div className="form-group">
      <button
        disabled={disabled}
        type="submit"
        className="btn btn-primary mt-3 form-control"
        onClick={() => setPost({ ...post, status: 1 })}
      >
        Publish
      </button>
      <span>
        <button
          disabled={disabled}
          type="submit"
          onClick={() => setPost({ ...post, status: 0 })}
          className="btn btn-link"
        >
          or save as Draft
        </button>
      </span>
    </div>
  );
};
export default SubmitButtonComponent;
