import React, { useContext, useEffect } from "react";
import MetaTags from "react-meta-tags";
import kuwait from "./assets/kuwait-panaroma.jpg";
import { LoadingContext } from "../../components/loading/Loading";

const About = () => {
  const setLoading = useContext(LoadingContext);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [setLoading]);

  return (
    <>
      <MetaTags>
        <title>About Know Kuwait</title>
      </MetaTags>
      <div
        className="row"
        style={{
          backgroundImage: `url(${kuwait})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          minHeight: "500px",
        }}
      >
        <div className="container">
          <div className="row" style={{ height: "500px" }}>
            <div className="col-12">
              <span
                style={{
                  position: "absolute",
                  top: "10%",
                  left: "5%",
                  maxWidth: "90%",
                }}
              >
                <h1 className="bg-desert p-2">About KnowKuwait</h1>
                <h2 className="bg-desert p-2">
                  Kuwait's independent digital media company
                </h2>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <div className="col-12">
            <p>
              KnowKuwait is Kuwait's independent digital media company that
              leverages innovation to reach a global audience. We want to be
              your window to Kuwait, to see it like never before.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
