import React, { useContext } from "react";
import { ContactContext } from "./ContactForm";

const ContactFullNameComponent = () => {
  const { form, setForm } = useContext(ContactContext);
  return (
    <div className="col-12 form-group">
      <label className="h3" htmlFor="full_name">
        Full Name
      </label>
      <input
        type="text"
        autoComplete="off"
        className="form-control"
        name="full_name"
        value={form.full_name}
        onChange={(event) =>
          setForm({
            ...form,
            [event.target.name]: event.target.value,
          })
        }
      />
      <div class="invalid-feedback">Please use your real name.</div>
    </div>
  );
};

export default ContactFullNameComponent;
