import React, { useContext } from "react";
import { LoginContext } from "./Login";
const EmailInputComponent = () => {
  const { form, setForm } = useContext(LoginContext);
  return (
    <div className="form-group">
      <label htmlFor="email">Email</label>
      <input
        type="text"
        className="form-control"
        id="email"
        value={form.email}
        name="email"
        placeholder="Email"
        onChange={(event) =>
          setForm({
            ...form,
            [event.target.name]: event.target.value,
          })
        }
      />
    </div>
  );
};
export default EmailInputComponent;
