import React from "react";
const PostTableHead = () => {
  return (
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Post Title</th>
        <th scope="col">Publish Date</th>
        <th scope="col">Category</th>
        <th scope="col">Tags</th>
        <th scope="col">Author</th>
        <th scope="col">Status</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
  );
};
export default PostTableHead;
