import React, { useState, createContext } from "react";
import axios from "axios";
import ContactFullNameComponent from "./ContactFullNameComponent";
import ContactEmailComponent from "./ContactEmailComponent";
import ContactPhoneNumberComponent from "./ContactPhoneNumberComponent";
import ContactMessageComponent from "./ContactMessageComponent";
import ContactSubmtiComponent from "./ContactSubmitComponent";
import ContactUpperMessage from "./ContactUpperText";
export const ContactContext = createContext();

const ContactForm = ({ setFormStatus }) => {
  const [form, setForm] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("https://formspree.io/mbjavnpy", form)
      .then((res) => setFormStatus("success"))
      .catch((e) => setFormStatus("failure"));
  };

  return (
    <ContactContext.Provider value={{ form, setForm }}>
      <div className="col-12 col-md-6 col-lg-3 mx-auto form-group my-5">
        <form onSubmit={handleSubmit}>
          <ContactUpperMessage />
          <ContactFullNameComponent />
          <ContactEmailComponent />
          <ContactPhoneNumberComponent />
          <ContactMessageComponent />
          <ContactSubmtiComponent />
        </form>
      </div>
    </ContactContext.Provider>
  );
};

export default ContactForm;
