import React from "react";
import twitterIcon from "../social-media-icons/Twitter_Logo_Blue.svg";
import instagramIcon from "../social-media-icons/instagram-2-1.svg";
import facebookIcon from "../social-media-icons/facebook-icon.svg";
import FooterSocialMediaLink from "./FooterSocialMediaLink";
export default function FooterSocialMedia() {
  return (
    <div className="col">
      <h6>Social Media</h6>
      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        <FooterSocialMediaLink
          name="Twitter"
          icon={twitterIcon}
          link="https://twitter.com/knowkuwait"
        />
        <FooterSocialMediaLink
          name="Instagram"
          icon={instagramIcon}
          link="https://instagram.com/knowkuwait"
        />
        <FooterSocialMediaLink
          name="Facebook"
          icon={facebookIcon}
          link="https://facebook.com/knowkuwait"
        />
      </ul>
    </div>
  );
}
