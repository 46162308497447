import React from "react";
import { Link } from "react-router-dom";
const Dashboard = () => {
  return (
    <div className="container">
      <h2>This is the Dashboard</h2>
      <ul>
        <li>
          <Link to="/admin/posts">Manage Posts</Link>
        </li>
        <li>
          <Link to="/admin/categories">Manage Categories</Link>
        </li>
        <li>
          <Link to="/admin/users">Manage Users</Link>
        </li>
      </ul>
    </div>
  );
};
export default Dashboard;
