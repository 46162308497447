import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import adminStore from "../../stores/AdminStore";
import { observer } from "mobx-react";
import { AlertContext } from "../../components/alert/Alert";

const ManageUsers = () => {
  useEffect(() => {
    adminStore.user.list();
  }, []);
  return (
    <div className="container">
      <div className="mb-3">
        <Link to="/admin/users/new">Add new user</Link>
      </div>
      {adminStore.users.map((user) => (
        <div>
          <span>{user.full_name}</span>
          <span style={{ marginLeft: "10px" }}>Edit</span>
          <span
            style={{ marginLeft: "10px" }}
            onClick={(e) => adminStore.user.delete(user.id)}
          >
            Delete
          </span>
        </div>
      ))}
    </div>
  );
};
export default observer(ManageUsers);
