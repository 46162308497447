import React, { useContext } from "react";
import { PostContext } from "./Post";
import { Link } from "react-router-dom";
const PostRelatedPosts = () => {
  const { post } = useContext(PostContext);
  return (
    <div className="col-12">
      <h4 className="color-black">Related Posts</h4>
      {post.related_posts &&
        post.related_posts.map(({ categories, title, slug }) => (
          <li>
            <Link to={`/${categories[0]}/${slug}`}>{title}</Link>
          </li>
        ))}
    </div>
  );
};

export default PostRelatedPosts;
