import React from "react";
const FooterSocialMediaLink = ({ icon, link, name }) => {
  return (
    <li style={{ listStyle: "none", margin: 0, padding: 0 }}>
      <a href={link}>
        <img
          src={icon}
          style={{ width: "20px", height: "auto", marginRight: "10px" }}
        />
        {name}
      </a>
    </li>
  );
};
export default FooterSocialMediaLink;
