import React, { useState, useEffect, useContext } from "react";
import SmallView from "./SmallView";
import LargeView from "./LargeView";
import MediumView from "./MediumView";
import { useWindowSize } from "../../utils/customHooks";
import { instance } from "../../stores/AuthStore";
import { LoadingContext } from "../../components/loading/Loading";
import { useRouteMatch } from "react-router-dom";

const Posts = () => {
  const setLoading = useContext(LoadingContext);

  const [posts, setPosts] = useState([]);
  useEffect(() => {
    setLoading(true);
    instance
      .get("/blog/posts/list/")
      .then((res) => setPosts(res.data))
      .then(
        setTimeout(() => {
          setLoading(false);
        }, 0)
      );
  }, [setLoading]);
  const size = useWindowSize();
  if (size.width >= 992) {
    return <LargeView posts={posts} />;
  } else if (size.width >= 768) {
    return <MediumView posts={posts} />;
  } else {
    return <SmallView posts={posts} />;
  }
};

export default Posts;
