import React from "react";
import Posts from "./posts/Posts";
import Ticker from "../components/ticker/Ticker";

const Home = () => {
  return (
    <>
      <div className="container">
        <Ticker />
        <Posts />
      </div>
    </>
  );
};

export default Home;
