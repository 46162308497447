import React, { useState, useEffect } from "react";
import { instance } from "../../stores/AuthStore";
import TickerComponent from "./TickerComponent";

const Ticker = () => {
  const [data, setData] = useState();
  useEffect(() => {
    instance.get("blog/ticker/").then((res) => setData(res.data));
  }, []);
  if (data) {
    return (
      <div className="row color-desert justify-content-md-center my-3">
        <TickerComponent type="24K Gold (KWD)" data={data.gold} />
        <TickerComponent type="KEC Oil (USD)" data={data.oil} />
        <TickerComponent type="1USD/FILS" data={data.usdkwd} />
      </div>
    );
  } else {
    return null;
  }
};

export default Ticker;
