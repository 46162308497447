import React, { useState, useContext, createContext } from "react";
import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import adminStore from "../../../stores/AdminStore";
import { AlertContext } from "../../../components/alert/Alert";
import FullNameComponent from "./FullNameComponent";
import EmailComponent from "./EmailComponent";
import PasswordComponent from "./PasswordComponent";
import PhoneNumberComponent from "./PhoneNumberComponent";
import TypeComponent from "./TypeComponent";
export const UserContext = createContext();

const UserEditor = ({ user }) => {
  const setAlert = useContext(AlertContext);
  const [form, setForm] = useState({});
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    adminStore.user
      .new(form)
      .then((res) => navigate("/admin/users", { replace: true }))
      .catch((e) => setAlert(e.message));
  };

  return (
    <UserContext.Provider value={{ form, setForm }}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <FullNameComponent />
            <EmailComponent />
            <PasswordComponent />
            <PhoneNumberComponent />
            <TypeComponent />
            <button className="btn btn-primary mt-3">Publish</button>
          </div>
        </div>
      </form>
    </UserContext.Provider>
  );
};

export default UserEditor;
