import React, { createContext } from "react";
import PostTableRowCategoryComponent from "./PostTableRowCategoryComponent";
import { observer } from "mobx-react";
import PostTableRowUserComponent from "./PostTableRowUserComponent";
import PostTableRowTagComponent from "./PostTableRowTagComponent";
import PostTableRowStatusComponent from "./PostTableRowStatusComponent";
import PostTableRowActionsComponent from "./PostTableRowActionsComponent";
import PostTableRowTitleComponent from "./PostTableRowTitleComponent";
export const PostTableRowContext = createContext();
const PostTableRow = ({ index, post }) => {
  return (
    <PostTableRowContext.Provider value={{ post }}>
      <tr>
        <th scope="row">{index}</th>
        <PostTableRowTitleComponent />
        <td>{post.publish_date}</td>
        <PostTableRowCategoryComponent />
        <PostTableRowTagComponent />
        <PostTableRowUserComponent />
        <PostTableRowStatusComponent />
        <PostTableRowActionsComponent />
      </tr>
    </PostTableRowContext.Provider>
  );
};
export default observer(PostTableRow);
