import React, { useEffect, useContext, useState, createContext } from "react";
import { useNavigate, useParams } from "react-router";
import { AlertContext } from "../../../../components/alert/Alert";
import formatDate from "../../../../utils/formatDate";
import adminStore from "../../../../stores/AdminStore";
import { toJS } from "mobx";
import TitleComponent from "./TitleComponent";
import ExcerptComponent from "./ExcerptComponent";
import ContentComponent from "./ContentComponent";
import CategoryComponent from "./CategoryComponent";
import FeaturedImageComponent from "./FeaturedImageComponent";
import SubmitButtonCompnent from "./SubmitButtonComponent";
import UserComponent from "./UserComponent";
import TagComponent from "./TagComponent";
import DatePickerComponent from "./DatePickerComponent";
import authStore, { instance } from "../../../../stores/AuthStore";

export const EditorContext = createContext();

const PostEditor = () => {
  const [post, setPost] = useState({});
  const navigate = useNavigate();
  const [view, setView] = useState({});
  const setAlert = useContext(AlertContext);
  const { slug } = useParams();

  useEffect(() => {
    if (!slug) {
      navigate(`/admin`);
    }
    adminStore.post
      .adminDetails(slug)
      .then((res) => {
        res.publish_date = new Date(res.publish_date);
        res.categories = [res.categories[0]];
        res.user = res.user.id;
        setPost(res);
      })
      .catch((e) => setAlert(e.message));
  }, []);

  useEffect(() => {
    console.log("post", toJS(post));
  }, [post]);

  // const validateEditor = (post) => {
  //   // check tags
  //   let tags = post.tags ? post.tags.length : 0;
  //   if (2 > tags) {
  //     document.getElementsByName("tags")[0].classList.add("is-invalid");
  //   }
  //   // check category
  //   let category = post.categories ? post.categories[0] : "uncategorized";
  //   if (category === "uncategorized") {
  //     document.getElementsByName("categories")[0].classList.add("is-invalid");
  //   }
  //   // check title
  //   let regex = /^(\w+\s?){1,10}/;
  //   let title = post.title;
  //   if (regex.test(title)) {
  //     document.getElementsByName("title")[0].classList.add("is-invalid");
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    // validateEditor(post);
    let post_data = new FormData();
    let tags = [];
    let categories = [];
    for (const [key, value] of Object.entries(post)) {
      key === "tags"
        ? (tags = value)
        : key === "categories"
        ? (categories = value)
        : key === "featured_image"
        ? post_data.append(key, value, value.name)
        : key === "publish_date"
        ? post_data.append(key, formatDate(value))
        : post_data.append(key, value);
    }
    let url = slug ? `/blog/test/${slug}/edit/` : `/blog/test/create_post/`;
    let func = slug ? instance.patch : instance.post;
    func(url, post_data)
      .then((res) =>
        instance
          .post(`/blog/test/add_tags/`, {
            post_slug: res.data.slug,
            tags: tags,
          })
          .then((res) =>
            instance.post(`/blog/test/add_categories/`, {
              post_slug: res.data.post_slug,
              categories: categories,
            })
          )
          .then((res) =>
            navigate(`/${res.data.categories[0]}/${res.data.post_slug}`, {
              replace: true,
            })
          )
      )

      .catch((e) => setAlert(e.message));
  };

  return (
    <EditorContext.Provider value={{ view, post, setPost }}>
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-2 order-3 order-md-1">
              <SubmitButtonCompnent />
              <UserComponent />
              <FeaturedImageComponent />
              <DatePickerComponent />
            </div>
            <div className="col-12 col-md-8 order-1 order-md-2">
              <TitleComponent />
              <ExcerptComponent />
              <ContentComponent />
            </div>

            <div className="col-12 col-md-2 order-2 order-md-3">
              <CategoryComponent />
              <TagComponent />
            </div>
          </div>
        </div>
      </form>
    </EditorContext.Provider>
  );
};
export default PostEditor;
