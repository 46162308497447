import { decorate, observable } from "mobx";
import { instance } from "./AuthStore";
import removeObjectFromArray from "../utils/removeObjectFromArray";
class PanelStore {
  categories = [];
  posts = [];
  users = [];
  category = {
    new: (name) =>
      new Promise((resolve, reject) => {
        const payload = { name: name };
        instance
          .post("/blog/categories/add/", payload)
          .then((res) => {
            this.categories.push(res.data);
            resolve(res);
          })
          .catch((e) => reject(e));
      }),
    delete: (slug) =>
      new Promise((resolve, reject) => {
        instance
          .delete(`/blog/categories/${slug}/delete/`)
          .then((res) => {
            this.categories = removeObjectFromArray(
              this.categories,
              "slug",
              slug
            );
            resolve(res);
          })
          .catch((e) => reject(e));
      }),
    edit: (slug, newName) =>
      new Promise((resolve, reject) => {
        const payload = { name: newName };
        instance
          .patch(`/blog/categories/${slug}/edit/`, payload)
          .then((res) => {
            this.categories = removeObjectFromArray(
              this.categories,
              "slug",
              slug
            );
            this.categories.push(res.data);
            resolve(res);
          })
          .catch((e) => reject(e));
      }),
    add: (name, postSlug) =>
      new Promise((resolve, reject) => {
        instance
          .post(`/blog/categories/${name}/`)
          .then((res) => resolve(res))
          .catch((e) => reject(e));
      }),
    list: (slug) => {
      new Promise((resolve, reject) => {
        if (slug) {
          instance
            .get(`/blog/categories/${slug}/list/`)
            .then((res) => {
              this.posts = res.data;
              resolve(res);
            })
            .catch((e) => reject(e));
        } else {
          instance
            .get(`/blog/categories/list/`)
            .then((res) => {
              this.categories = res.data;
              resolve(res);
            })
            .catch((e) => reject(e));
        }
      });
    },
  };
  post = {
    delete: (slug) =>
      new Promise((resolve, reject) => {
        instance
          .delete(`/blog/posts/${slug}/delete/`)
          .then((res) => {
            this.posts = removeObjectFromArray(this.posts, "slug", slug);

            resolve(res);
          })
          .catch((e) => reject(e));
      }),
    list: () =>
      new Promise((resolve, reject) => {
        instance
          .get("/blog/posts/list/")
          .then((res) => {
            this.posts = res.data;
            resolve(res);
          })
          .catch((e) => reject(e));
      }),
    adminList: () =>
      new Promise((resolve, reject) => {
        instance
          .get("/blog/posts/admin_operations/list/")
          .then((res) => {
            this.posts = res.data;
            resolve(res);
          })
          .catch((e) => reject(e));
      }),
    adminDetails: (postSlug) =>
      new Promise((resolve, reject) => {
        let post = {};
        instance
          .get(`blog/posts/admin_operations/details/${postSlug}`)
          .then((res) => {
            post = res.data;
            if (post.featured_image) {
              instance
                .get(post.featured_image, { responseType: "blob" })
                .then((imageRes) => {
                  post.featured_image = imageRes.data;
                  post.featured_image.name = imageRes.config.url
                    .split("/")
                    .pop();
                  resolve(post);
                });
            } else {
              resolve(post);
            }
          })
          .catch((e) => reject(e));
      }),
    details: (postSlug) =>
      new Promise((resolve, reject) => {
        instance
          .get(`/blog/posts/${postSlug}/details/`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => reject(e));
      }),
    edit: (slug, payload) =>
      new Promise((resolve, reject) => {
        instance
          .patch(`/blog/posts/${slug}/edit/`, payload, {
            headers: { "content-type": `multipart/form-data` },
          })
          .then((res) => resolve(res))
          .catch((e) => reject(e));
      }),
    new: (payload) =>
      new Promise((resolve, reject) => {
        instance
          .post(`/blog/posts/create/`, payload, {
            headers: { "content-type": `multipart/form-data` },
          })
          .then((res) => resolve(res))
          .catch((e) => reject(e));
      }),
  };
  user = {
    new: (payload) =>
      new Promise((resolve, reject) => {
        instance
          .post("/users/admin_operations/create/", payload)
          .then((res) => {
            this.users.push(res.data);
            resolve(res);
          })
          .catch((e) => reject(e));
      }),
    delete: (id) =>
      new Promise((resolve, reject) => {
        instance
          .delete(`/users/admin_operations/${id}/delete/`)
          .then((res) => {
            this.users = removeObjectFromArray(this.users, "id", id);
            resolve(res);
          })
          .catch((e) => reject(e));
      }),
    edit: (id, payload) =>
      new Promise((resolve, reject) => {
        instance
          .patch(`/users/admin_operations/${id}/edit/`, payload)
          .then((res) => {
            this.users = removeObjectFromArray(this.users, "id", id);
            this.users.push(res.data);
            resolve(res);
          })
          .catch((e) => reject(e));
      }),
    list: () => {
      new Promise((resolve, reject) => {
        instance
          .get(`/users/admin_operations/list/`)
          .then((res) => {
            this.users = res.data;
            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },
  };
}

decorate(PanelStore, {
  categories: observable,
  posts: observable,
  users: observable,
});

const adminStore = new PanelStore();

export default adminStore;
