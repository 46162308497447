import React from "react";

const ContactFormFailure = () => {
  return (
    <div className="row pt-3">
      <div className="offset-md-3 col-md-6">
        <p>There was an issue with submitting your form. Please try later.</p>
      </div>
    </div>
  );
};

export default ContactFormFailure;
