import React, { useContext } from "react";
import { LoginContext } from "./Login";
const PasswordInputComponent = () => {
  const { form, setForm } = useContext(LoginContext);
  return (
    <div className="form-group">
      <label htmlFor="password">Password</label>
      <input
        type="password"
        className="form-control"
        id="password"
        value={form.password}
        name="password"
        placeholder="Password"
        onChange={(event) =>
          setForm({
            ...form,
            [event.target.name]: event.target.value,
          })
        }
      />
    </div>
  );
};
export default PasswordInputComponent;
