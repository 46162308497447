import React, { useEffect, useContext, useState } from "react";
import { PostTableRowContext } from "./PostTableRow";
import { instance } from "../../../stores/AuthStore";
import { ManagePostsContext } from "../ManagePosts";

const PostTableRowUserComponent = () => {
  const { post } = useContext(PostTableRowContext);
  const { users } = useContext(ManagePostsContext);
  const [value, setValue] = useState();

  useEffect(() => {
    if (post.user) {
      setValue(post.user.id);
    }
  }, [post]);

  const handleChange = (event) => {
    let post_data = new FormData();
    post_data.append(event.target.name, event.target.value);
    instance.patch(`/blog/test/${post.slug}/edit/`, post_data).then((res) => {
      setValue(res.data.user);
      console.log(value);
    });
  };

  return (
    <td>
      <select
        name="user"
        value={value}
        className="form-control"
        onChange={handleChange}
      >
        {users.map((item) => (
          <option key={item.full_name} value={item.id}>
            {item.full_name}
          </option>
        ))}
      </select>
    </td>
  );
};
export default PostTableRowUserComponent;
