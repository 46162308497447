import React from "react";
import styled from "styled-components";
import logo from "../../assets/knowkuwait-logo.svg";
import { NavLink, Link } from "react-router-dom";

const StyledNavigationBar = styled.div`
  width: 100%;
  height: 70px;
  background-color: #005dbc;
  border-bottom: 2px solid #ff7e51;
`;
const StyledLogoContainer = styled.div`
  height: 100%;
  float: left;
`;
const StyledLogo = styled.img`
  width: auto;
  height: 70px;
  padding: 10px 10px 10px 0;
`;
const StyledLinksContainer = styled.div`
  width: 60%;
  height: 100%;
  float: left;
`;

const StyledLi = styled.li`
  position: relative;
  display: block;
  transition-duration: 0.5s;
`;
const activeClassName = "color-green";
const StyledLink = styled(NavLink).attrs({
  activeClassName,
})`
  &.${activeClassName} {
    background-color: #fff;
    color: #ff7e51;
    text-decoration: none;
  }
  &.${activeClassName}:hover {
    color: #005dbc;
  }
  &:hover {
    text-decoration: none;
    color: #fff;
  }
  color: #fff;
  margin: 0;
  float: left;
  height: 70px;
  text-align: center;
  padding: 0 20px 0 20px;
  font-size: 1em;
  line-height: 5em;
  list-style: none;
  text-decoration: none;
  font-weight: bold;
`;
const NavigationBar = ({ routes }) => {
  const NavigationLink = ({ route }) => {
    return (
      <StyledLi>
        <StyledLink className="color-black" to={route.path}>
          {route.name}
        </StyledLink>
      </StyledLi>
    );
  };
  return (
    <div className="row">
      <StyledNavigationBar>
        <div className="container">
          <StyledLogoContainer>
            <Link to="/">
              {" "}
              <StyledLogo src={logo} />
            </Link>
          </StyledLogoContainer>
          <StyledLinksContainer>
            <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
              {routes.map((route) => (
                <NavigationLink key={route.name} route={route} />
              ))}
            </ul>
          </StyledLinksContainer>
        </div>
      </StyledNavigationBar>
    </div>
  );
};

export default NavigationBar;
