import React from "react";
import FooterLinks from "./FooterLinks";
import FooterSocialMedia from "./FooterSocialMedia";
import FooterInfo from "./FooterInfo";
export default function Footer() {
  return (
    <div className="container-fluid">
      <div className="row footer bg-light p-3">
        <div className="container">
          <div className="row">
            <FooterLinks />
            <FooterSocialMedia />
            <FooterInfo />
          </div>
        </div>
      </div>
    </div>
  );
}
