import React, { useEffect, createContext, useState } from "react";
import loadingImage from "./assets/loading-image.gif";
export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Loading hide={loading} />
      <LoadingContext.Provider value={setLoading}>
        {children}
      </LoadingContext.Provider>
    </>
  );
};
const Loading = ({ hide }) => {
  const style = {
    zIndex: 100,
    top: 0,
    left: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${loadingImage})`,
    backgroundColor: "#fff",
    backgroundRepeat: "no-repeat no-repeat",
    backgroundPosition: "center center",
    overflow: "hidden",
    visibility: "hidden",
  };

  useEffect(() => {
    const loading = document.getElementById("loading");
    hide
      ? (loading.style.visibility = "visible")
      : (loading.style.visibility = "hidden");
  }, [hide]);
  return <div id="loading" style={style} />;
};
export default Loading;
