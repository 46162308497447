import React, { useEffect, useContext } from "react";
import { EditorContext } from "./PostEditor";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
const DatePickerComponent = () => {
  const { view, post, setPost } = useContext(EditorContext);
  // useEffect(() => {
  //   if (view.publish_date) {
  //     setPost({ ...post, publish_date: view.publish_date });
  //   }
  // }, [view, post, setPost]);

  return (
    <div className="form-group">
      <label className="h3">Publish Date</label>
      <div>
        <Calendar
          value={post.publish_date || view.publish_date}
          onChange={(date) =>
            setPost({ ...post, publish_date: new Date(date) })
          }
        />
      </div>
    </div>
  );
};
export default DatePickerComponent;
